import React from 'react'
import CountUp from 'react-countup'

class CountersInner extends React.Component {
  componentWillReceiveProps(nextProps) {
    const { isVisible } = this.props
    if (nextProps.isVisible && !isVisible) {
      this.stats1()
      this.stats2()
      this.stats3()
      this.stats4()
    }
  }
  render() {
    const { data } = this.props

    return (
      <div className="container text-center">
          <div className="row">
            <div className="item col-lg-3 col-md-6">
              <CountUp start={1900} end={data.stats_1_value} duration={2.75}>
                {({ countUpRef, start }) => {
                  this.stats1 = start
                  return <strong ref={countUpRef} />
                }}
              </CountUp>
              <p>{data.stats_1_label}</p>
            </div>
            <div className="item col-lg-3 col-md-6">
              <CountUp start={0} end={data.stats_2_value} duration={2.75} suffix="+">
                {({ countUpRef, start }) => {
                  this.stats2 = start
                  return <strong ref={countUpRef} />
                }}
              </CountUp>
              <p>{data.stats_2_label}</p>
            </div>
            <div className="item col-lg-3 col-md-6">
              <CountUp start={0} end={data.stats_3_value} duration={2.75} suffix="+">
                {({ countUpRef, start }) => {
                  this.stats3 = start
                  return <strong ref={countUpRef} />
                }}
              </CountUp>
              <p>{data.stats_3_label}</p>
            </div>
            <div className="item col-lg-3 col-md-6">
              <CountUp start={0} end={data.stats_4_value} duration={2.75} suffix="+">
                {({ countUpRef, start }) => {
                  this.stats4 = start
                  return <strong ref={countUpRef} />
                }}
              </CountUp>
              <p>{data.stats_4_label}</p>
            </div>
          </div>
      </div>
    )
  }
}

CountersInner.defaultProps = {
  isVisible: false,
}

export default CountersInner
