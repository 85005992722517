/* eslint-disable camelcase */
import React, {useState} from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'

import SEO from '../components/SEO'
import Carousel from '../components/Carousel/Carousel'
import Testimonials from '../components/Testimonials/Testimonials'
import '../css/swiper.css';
import BlogPostItem from '../components/BlogPostItem'
import LocalizedLink from '../components/LocalizedLink'
import Counters from '../components/Counters/Counters'

const Index = ({ data: { homepage, reviews, blogs }}) => {

  const { data: homePageData } = homepage || {}

  const [videoVisible, setVideoVisible] = useState(false)

  const {
    carouselData,
    stepData,
    main_pre,
    main_text,
    main_description,
    tour_pre,
    tour_title,
    tour_text,
    tour_video,
    tour_video_image,
    register_title,
    register_description,
    register_apply_text,
    register_offer_text,
    stats_1_label,
    stats_1_value,
    stats_2_label,
    stats_2_value,
    stats_3_label,
    stats_3_value,
    stats_4_label,
    stats_4_value,
  } = homePageData

  const embedParts = tour_video.embed_url.split('?v=')
  const videoUrl = `https://www.youtube.com/embed/${embedParts[1]}?feature=oembed&autoplay=1`

  return (
    <>

      <SEO pathname="/" />
      {/* Hero Section */}
      <section className="hero hero-home">
        <div className="swiper-container hero-slider">
          <Carousel data={carouselData} className="home_slider" />
          {/* Add Pagination */}

        </div>
      </section>
      {/* Info Boxes Section */}
      <section className="info-boxes">
        <div className="container">
          <div className="row">
            {stepData.map((stepItem, index) => (
              <a key={stepItem.primary.link.url} href={stepItem.primary.link.url.startsWith('/') ? `/a${stepItem.primary.link.url}` : stepItem.primary.link.url  } style={{background: `url(img/boxes-img-${index%3 + 1}.jpeg)`}} className={`info-box col-lg-4`}>
                <div className="info-box-before" style={{background: stepItem.primary.background}} />
                <div className="info-box-content">
                  <h3 className="text-uppercase">{stepItem.primary.steptitle.text}</h3>
                  <p>{stepItem.primary.description.text}</p>
                </div>
              </a>
            ))}
          </div>
        </div>
      </section>
      {/* Intro Section */}
      <section className="intro">
        <div className="container text-center">
          <header>
            <h2><small>{main_pre}</small>{main_text}</h2>
          </header>
          <div className="row">
            <div className="col-lg-8 mx-auto" dangerouslySetInnerHTML={{__html: main_description.html}} />
          </div>
        </div>
      </section>
      {/* Statistics Section */}
      <section className="statistics pt-0 pb-0 bg-primary has-pattern">
        <Counters data={{ stats_1_label, stats_1_value, stats_2_label, stats_2_value, stats_3_label, stats_3_value, stats_4_label, stats_4_value}} />
      </section>
      {/* Tour Section */}
      <section className="tour bg-gray">
        <div className="container">
          <div className="row d-flex align-items-center">
            <div className="col-lg-6">
              <div className="video">
                {videoVisible && <iframe src={videoUrl} frameBorder="0" style={{width: '100%', height: '100%'}} />}
                {!videoVisible &&
                  <>
                    <Img fluid={tour_video_image.localFile.childImageSharp.fluid} style={{paddingBottom: '100%'}} />
                    <div onClick={() => setVideoVisible(true)} className="overlay d-flex align-items-center justify-content-center" style={{backgroundColor: 'inherit', cursor: 'pointer'}}><img src="img/play.svg" /></div>
                  </>
                }
              </div>
            </div>
            <div className="col-lg-6 text">
              <header>
                <h2><small>{tour_pre}</small>{tour_title}</h2>
              </header>
              <p className="h4">{tour_text.text}</p>
            </div>
          </div>
        </div>
      </section>
      {/* Divider Section */}
      <section style={{ background: 'url(img/divider-bg.jpg) fixed'}} className="divider has-overlay-darker">
        <div className="container">
          <h2>{register_title}</h2>
          <div className="row">
            <p className="col-lg-8">{register_description}</p>
          </div>
          <div className="CTAs">
            <LocalizedLink to="/kontakt" className="btn btn-info">{register_apply_text}</LocalizedLink>
            <LocalizedLink to="/kursy" className="btn btn-outline-light">{register_offer_text}</LocalizedLink>
          </div>
        </div>
      </section>
      {/* Blog Section */}
      <section className="blog bg-gray">
        <div className="container">
          <header className="text-center">
            <h2> <small>Z naszego bloga</small>Ostatnie posty</h2>
          </header>
          <div className="row">
            {blogs && blogs.edges.map((item) => <BlogPostItem key={item.node.id} item={item}/>)}
          </div>
        </div>
      </section>

      {/* Testimonials Section */}
      <section className="testimonials">
        <div className="container text-center">
          <header>
            <h2> <small>W słowach naszych uczniów</small>Opinie o szkole</h2>
          </header>
          <div className="swiper-container testimonials-slider">
            <Testimonials
              data={reviews.edges}
            />
          </div>
        </div>
      </section>
    </>
  )
}

export default Index

Index.propTypes = {
  data: PropTypes.shape({
    homepage: PropTypes.object.isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
}

export const pageQuery = graphql`
  query IndexQuery {
    homepage: prismicHomepage {
      data {
        title {
          text
        }
        content {
          text
        }
        main_pre
        main_text
        main_description {
          html
        }
        carouselData {
          id
          slice_type
          primary {
            carousel_slide_title {
              text
            }
            carousel_slide_text {
              text
            }
            carousel_image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1920, quality: 75) {
                    tracedSVG
                    aspectRatio
                    src
                    srcSet
                    srcWebp
                    srcSetWebp
                    sizes
                    originalImg
                    originalName
                    presentationWidth
                    presentationHeight
                  }
                }
              }
            }
          }
        }
        stepData {
          id
          slice_type
          primary {
            steptitle {
              text
            }
            description {
              text
            }
            background
            link {
                url
            }
          }
        }
        tour_pre
        tour_title
        tour_text {
          text
        }
        tour_video {
          thumbnail_url
          embed_url
        }
        tour_video_image {
            localFile {
                childImageSharp {
                    fluid(maxWidth: 1920, quality: 75) {
                        tracedSVG
                        aspectRatio
                        src
                        srcSet
                        srcWebp
                        srcSetWebp
                        sizes
                        originalImg
                        originalName
                        presentationWidth
                        presentationHeight
                    }
                }
            }
        }
        register_title
        register_apply_text
        register_offer_text
        register_description
        stats_1_label
        stats_1_value
        stats_2_label
        stats_2_value
        stats_3_label
        stats_3_value
        stats_4_label
        stats_4_value
      }
    }
    reviews: allPrismicReview {
      edges {
         node {
             id
            data {
              name
              position
              text {
                text
              }
              image {
                localFile {
                  childImageSharp {
                    fixed(width: 100) {
                      src
                    }
                    }
                  }
                }
              }
            }
         }
    }
    blogs: allPrismicBlog(sort: {fields: [first_publication_date], order:DESC}, limit: 3) {
        edges {
            node {
                id
                uid
                data {
                    title
                    description
                    image {
                        localFile {
                            childImageSharp {
                                fluid(maxWidth: 300, quality: 75) {
                                    tracedSVG
                                    aspectRatio
                                    src
                                    srcSet
                                    srcWebp
                                    srcSetWebp
                                    sizes
                                    originalImg
                                    originalName
                                    presentationWidth
                                    presentationHeight
                                }
                            }
                        }
                    }
                }
            }
        }
    }

  }
`
