import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import LocalizedLink from '../LocalizedLink'

const BlogPostItem = ({ item, gray }) => {
  const { node: { data } } = item

  return (
    <div className="col-lg-4 blog-post">
        <div className="image">{data.image && data.image.localFile && <Img fluid={data.image.localFile.childImageSharp.fluid} alt={data.title} />}
          <div className="overlay d-flex align-items-center justify-content-center">
            <LocalizedLink to={`blog-post/${item.node.uid}`} className="btn btn-outline-light">Czytaj dalej</LocalizedLink>
          </div>
        </div>
        <div className={gray === true ? "text bg-gray" : "text bg-white"}>
          <LocalizedLink to={`blog-post/${item.node.uid}`}>
            <h4 className="text-this">{data.title}</h4>
          </LocalizedLink>
          <p>{data.description}</p>
        </div>
    </div>
  )
}

BlogPostItem.propTypes = {
  item: PropTypes.object.isRequired,
}

BlogPostItem.defaultProps = {
  gray: false,
}

export default BlogPostItem
