import React from 'react'
import PropTypes from 'prop-types'
import TrackVisibility from 'react-on-screen'

import CountersInner from './CountersInner'

class Counters extends React.Component {
  render() {
    const { data } = this.props
    return (
      <TrackVisibility once>
        <CountersInner data={data} />
      </TrackVisibility>
    )
  }
}

Counters.propTypes = {
  data: PropTypes.object,
}

export default Counters
