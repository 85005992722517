import React from 'react'

import Swiper from 'react-id-swiper'

import TestimonialItem from './TestimonialItem'

const params = {
  autoplay: {
    delay: 7000,
  },
  pagination: {
    el: '.swiper-pagination',
    type: 'bullets',
    clickable: true
  },
}

const Testimonials = ({ data }) => {
  return (
    <>
      <Swiper className="swiper-wrapper" {...params}>
          {data.map(item => (
            <TestimonialItem key={item.node.id} item={item.node.data} />
          ))}
      </Swiper>
      <div className="swiper-pagination" />
    </>
  )
}

export default Testimonials
