import React from 'react'
import PropTypes from 'prop-types'

const TestimonialItem = ({ item }) => {
   return (
    <div className="swiper-slide">
      <div className="testimonial">
        <div className="row">
          <p className="feedback col-lg-8 mx-auto">{item.text.text}</p>
        </div>
        <div className="user d-flex align-items-center justify-content-center">
          <div className="avatar"><img
            src={item.image.localFile.childImageSharp ? item.image.localFile.childImageSharp.fixed.src : ''}
            alt={item.name} className="img-fluid"/></div>
          <div className="title"><strong className="text-uppercase">{item.name}</strong><span>{item.position}</span>
          </div>
        </div>
      </div>
    </div>
  )
}

TestimonialItem.propTypes = {
  item: PropTypes.object.isRequired,
}

export default TestimonialItem
