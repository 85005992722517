import React from 'react'
import PropTypes from 'prop-types'
import BackgroundImage from 'gatsby-background-image'

const CarouselItem = ({ item }) => (
    <div className="swiper-slide">
      <BackgroundImage
        Tag="div"
        className={item.carousel_slide_title.text.length > 0 ? 'hero-content has-overlay-dark' : 'hero-content'}
        fluid={item.carousel_image.localFile.childImageSharp.fluid}
        backgroundColor="#fff"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <h1>{item.carousel_slide_title.text}&nbsp;</h1>
              <p className="hero-text pr-5">{item.carousel_slide_text.text}&nbsp;</p>
            </div>
          </div>
        </div>
      </BackgroundImage>
    </div>
)

CarouselItem.propTypes = {
  item: PropTypes.object.isRequired,
}

export default CarouselItem
